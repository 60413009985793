import React from 'react';
import { Input as KendoInput, InputProps as KendoInputProps } from '@progress/kendo-react-inputs';
import ControlWrapper, { ControlWrapperProps } from '../control-wrapper/ControlWrapper';
import ShortcutKey from '../../../components/shortcut-key/ShortcutKey';

export type InputProps = {
  innerRef?: ((instance: HTMLInputElement | null) => void);
  lookupIconClassName?: string;
  onLookupClick?: () => void;
  selectOnFocus?: boolean;
  displayShortcutKey?: string;
}
  & Pick<KendoInputProps, 'name' | 'id' | 'type' | 'inputMode' | 'value' | 'onChange' | 'onBlur' | 'onKeyDown' | 'onKeyUp' | 'onKeyPress' | 'disabled' | 'placeholder' | 'minLength' | 'maxLength' | 'step'>
  & ControlWrapperProps;

const Input: React.FC<InputProps> = React.memo(props => (
  <ControlWrapper
    xs={props.xs} sm={props.sm} md={props.md} lg={props.lg} xl={props.xl} xxl={props.xxl}
    offset={props.offset} offsetSm={props.offsetSm} offsetMd={props.offsetMd} offsetLg={props.offsetLg} offsetXl={props.offsetXl} offsetXxl={props.offsetXxl}
    m={props.m} mt={props.mt} mb={props.mb} ml={props.ml} mr={props.mr} mx={props.mx} my={props.my}
    error={props.error} label={props.label} disabled={props.disabled} className={props.className} title={props.title} suffix={props.suffix} style={props.style}
  >
    <KendoInput
      title={props.error as string}
      type={props.type}
      inputMode={props.inputMode}
      name={props.name}
      ref={e => props.innerRef && props.innerRef(e && e.element)}
      disabled={props.disabled}
      value={props.value}
      onChange={props.onChange}
      onBlur={props.onBlur}
      onFocus={e => props.selectOnFocus && e.target.select()}
      onKeyDown={props.onKeyDown}
      onKeyUp={props.onKeyUp}
      onKeyPress={props.onKeyPress}
      placeholder={props.placeholder}
      minLength={props.minLength}
      maxLength={props.maxLength}
      id={props.id}
      step={props.step}
      validityStyles={false}
    />
    {props.lookupIconClassName
      ?
      <span className="k-select" style={props.disabled ? { opacity: 0.65 } : undefined} onClick={!props.disabled ? props.onLookupClick : undefined}>
        <span className={['k-icon', props.lookupIconClassName].join(' ')}>
        </span>
      </span>
      : [] // null / false won't work with ControlWrapper, as it does increase React.children.count ...
    }
    <ShortcutKey shortcutKey={props.displayShortcutKey} />
  </ControlWrapper>
));

export default Input;
