import React from 'react';
import { L } from '../../../abp/utils';
import { ItemApi, ItemDto } from '../../../client/http';
import ItemRenderComponent from '../../../common/components/item-render/ItemRenderComponent';
import ApiComboBox from '../../../common/forms/validation/controls/api-combo-box/ApiComboBox';
import useApi from '../../../common/hooks/useApi';
import { FIELD_DESCRIPTION1, FIELD_NUMBER } from '../../../data/field-constants/GeneralConstants';
import { ItemSelectionProps } from '../../../module-components/service-entities/ServiceEntityControlProps';
import { Settings_ProviderName, Settings_ServiceConnectionId } from '../../../util/SettingNames';

const ItemSelection: React.FC<ItemSelectionProps> = ({ fetchFromAllProviders, selectedItem, itemRenderFields, data, itemFilters,
    name, title, placeholder, filterable, filterFields, dataItemKey, textField, itemRender, content, footer, ...props }) => {

    const apiResult = useApi<ItemDto, ItemApi>(c => new ItemApi(c), c => c.apiServicesAppItemGetAllGet(fetchFromAllProviders ? undefined :
        abp.setting.get(Settings_ProviderName), fetchFromAllProviders ? undefined : abp.setting.getInt(Settings_ServiceConnectionId),
        JSON.stringify(itemFilters)));

    return <ApiComboBox apiResult={data ? [data, undefined, undefined, () => { }, undefined] : apiResult} name={name} filterable={filterable ?? true} dataItemKey={dataItemKey ?? 'id'}
        textField={textField ?? FIELD_NUMBER} placeholder={placeholder ?? L('Item')}
        itemRender={(li, props) => <ItemRenderComponent li={li} itemProps={props} fields={itemRenderFields ?? [FIELD_NUMBER, FIELD_DESCRIPTION1]} />}
        footer={footer} content={content} popupSettings={{ width: 'min(400px,50vw)' }}
        filterFields={filterFields ?? [FIELD_NUMBER, FIELD_DESCRIPTION1]} {...props} />
}

export default ItemSelection;