import React, { useCallback, useMemo, useState } from 'react';
import { PlmControlBaseProps, PlmDataControlProps, RowSkeletons, isTemplateFilter, selectPlmControlSortProperty } from './components/PlmControlComponents';
import useInternalPlmControlData from '../../hooks/useInternalPlmControlData';
import { FIELD_ID, FIELD_IS_TEMPLATE, FIELD_JOB_TYPE_ID, FIELD_POSTING_TYPE } from '../../../../data/field-constants/GeneralConstants';
import { ItemApi, ItemDto, JobTypeLineApi, JobTypeLineDto } from '../../../../client/http';
import filter from '../../../../util/FilterUtils';
import { Settings_ProviderName, Settings_ServiceConnectionId } from '../../../../util/SettingNames';
import usePlmControlContext from './plm-control-context/usePlmControlContext';
import { PlmColumnDataDisplay } from './components/PlmControlDataDisplay';
import PlmColumnHeaderDisplay from './components/PlmControlHeaderDisplay';
import useInternalPlmListData from '../../hooks/useInternalPlmListData';
import _ from 'lodash';
import { NEW_ENTITY_ID } from '../../../../util/EntityUtils';
import classNames from 'classnames';
import PlmItemControl, { PlmItemControlProps } from './PlmItemControl';
import { getIn } from '../../../../common/forms/validation/na-va-form/commonUtils';
import useApi from '../../../../common/hooks/useApi';
import useLoadingState from '../../../../common/hooks/useLoadingState';
import { NullGuid } from '../../../../util/Values';
import { showNotification } from '../../../../common/components/notifications/NotificationHost';

export type PlmJobTypeLineListProps = {
    type: 'positive' | 'negative';
    jobTypeCode: string;
    jobTypeId: string;
}

export type PlmJobTypeLineControlProps = PlmDataControlProps & {
    jobTypeId: string,
    jobTypeLine: JobTypeLineDto;
}

const PlmJobTypeLineControl: React.FC<PlmJobTypeLineControlProps> = ({ jobTypeLine, jobTypeId, refetch }) => {

    const { controlState, showTemplates } = usePlmControlContext();

    const [internalJobTypeLine, , handleChange, handleAction, handleSave] = useInternalPlmControlData<JobTypeLineDto, JobTypeLineApi>(
        jobTypeLine,
        {
            [FIELD_JOB_TYPE_ID]: jobTypeId,
            [FIELD_IS_TEMPLATE]: showTemplates === true
        },
        'jobTypeLines',
        c => new JobTypeLineApi(c),
        c => c.apiServicesModulesSdtPlmJobTypeLineCreatePost,
        c => c.apiServicesModulesSdtPlmJobTypeLineUpdatePut,
        c => c.apiServicesModulesSdtPlmJobTypeLineDeleteDelete,
        refetch
    );

    // Item Logic

    const isExpanded = internalJobTypeLine && !!controlState.jobTypeLines.expanded?.find(x => x.key === getIn(internalJobTypeLine, FIELD_ID));

    const [item, setItem] = useState<ItemDto | undefined>(undefined);
    const [, itemApi] = useApi<ItemDto, ItemApi>(c => new ItemApi(c));
    const itemId = internalJobTypeLine?.itemId;
    const [startLoading, loadingComplete, loadingError, isItemLoading] = useLoadingState();

    const fetchItem = useCallback(async (iId?: string) => {
        iId = iId ?? itemId;

        if (!itemApi || !iId || iId === NEW_ENTITY_ID || iId === NullGuid) return;

        try {
            startLoading();

            const result = await itemApi.apiServicesAppItemGetByIdGet(iId, abp.setting.getInt(Settings_ServiceConnectionId),
                abp.setting.get(Settings_ProviderName));

            setItem((result as any).result);
            loadingComplete();
        } catch (error) {
            loadingError("Fehler beim Datenabruf");
        }
    }, [itemApi, itemId, startLoading, loadingComplete, loadingError])

    const handleItemChanged = useCallback(async (action: Parameters<PlmItemControlProps['onItemChanged']>[0], id?: string, code?: string) => {
        try {
            if (internalJobTypeLine) {

                let jtl: JobTypeLineDto;

                switch (action) {
                    case 'deleted':
                        jtl = { ...internalJobTypeLine };
                        jtl.itemId = '';
                        jtl.itemNumber = '';
                        await handleSave(jtl);
                        setItem(undefined);
                        break;
                    case 'created':
                    case 'updated':
                    default:
                        jtl = { ...internalJobTypeLine };
                        jtl.itemId = id;
                        jtl.itemNumber = code;
                        await handleSave(jtl);
                        await fetchItem(id);
                        break;
                }

            }

        } catch (error) {
            showNotification('Zuordnen des Artikels fehlgeschlagen', 'Fehler beim Speichern aufgetreten', 'error', undefined, true);
            return false;
        }
    }, [internalJobTypeLine, handleSave, fetchItem]);

    const localDispatch = useCallback((args: Parameters<PlmControlBaseProps['dispatch']>[0]) => {
        // fetch items on expand
        if (args.type === 'expanded/changed' && args.payload.value === true) {
            fetchItem();
        }
        // dispatch(args);
    }, [fetchItem]);

    return <div className={classNames('plm-job-type-line-wrapper')}>
        <PlmColumnDataDisplay item={internalJobTypeLine} section="jobTypeLines" level={2} className="plm-job-type-line-item" expandable
            onLocalDispatch={localDispatch} onChange={handleChange} onAction={handleAction} />

        {isExpanded &&
            <div className="job-type-line-meta-container item-row level-3">
                <PlmItemControl item={item} isLoading={isItemLoading} onItemChanged={handleItemChanged} />
            </div>
        }
    </div>
}

const POSITIVE_ADJMT = 'Positive Adjmt.';
const NEGATIVE_ADJMT = 'Negative Adjmt.';

const PlmJobTypeLineList: React.FC<PlmJobTypeLineListProps> = ({ type, jobTypeCode, jobTypeId }) => {

    const { fetchFromAllProviders, controlState, readOnly, showTemplates } = usePlmControlContext();

    const internalJobTypeControlStateFilters = controlState?.jobTypeLines?.filter?.internalFilters;
    const internalJobTypeLineFilters = useMemo(() => [
        ...internalJobTypeControlStateFilters,
        ...isTemplateFilter(showTemplates),
        filter('JobTypeId').equals(jobTypeId),
        filter('PostingType').equals(type === 'positive' ? POSITIVE_ADJMT : NEGATIVE_ADJMT)
    ], [internalJobTypeControlStateFilters, showTemplates, jobTypeId, type]);

    const externalJobTypeLineFilters = controlState?.jobTypeLines?.filter?.externalFilters;

    const getAll = useCallback((c: JobTypeLineApi) => {
        return c.apiServicesModulesSdtPlmJobTypeLineGetAllGet(
            fetchFromAllProviders ? undefined : abp.setting.get(Settings_ProviderName),
            fetchFromAllProviders ? undefined : abp.setting.getInt(Settings_ServiceConnectionId),
            internalJobTypeLineFilters ? JSON.stringify(internalJobTypeLineFilters) : undefined,
            JSON.stringify(externalJobTypeLineFilters)
        );
    }, [fetchFromAllProviders, internalJobTypeLineFilters, externalJobTypeLineFilters]);

    const [jobTypeLines, fetchJobTypeLines, addRow, isLoading] = useInternalPlmListData<JobTypeLineDto, JobTypeLineApi>(
        c => new JobTypeLineApi(c),
        getAll,
        'jobTypeLines',
        {
            jobTypeCode,
            [FIELD_POSTING_TYPE]: type === 'positive' ? POSITIVE_ADJMT : NEGATIVE_ADJMT
        }
    )

    return (<>

        <PlmColumnHeaderDisplay section="jobTypeLines" level={2} headersLevel={3} onAction={addRow} readOnly={readOnly || jobTypeId === NEW_ENTITY_ID}
            hasNoData={!isLoading && !jobTypeLines?.length} noDataLabel={type === 'positive' ? 'Keine Zugangsbuchungen vorhanden' : 'Keine Abgangsbuchungen vorhanden'} />

        {jobTypeLines?.map((jtl) => {

            return (<PlmJobTypeLineControl jobTypeId={jobTypeId} jobTypeLine={jtl} refetch={fetchJobTypeLines} />)
        })}

        {isLoading && <RowSkeletons level={1} />}
    </>);
}

export default PlmJobTypeLineList;