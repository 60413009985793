import React, { useMemo, useState } from 'react';
import { defaultColumnConfig } from '../data/columnConfigurations';
import { defaultPlmControlState } from './plm-job-list/components/PlmControlComponents';
import PlmJobList from './plm-job-list/PlmControl';
import filter from '../../../util/FilterUtils';
import NaVaForm from '../../../common/forms/validation/na-va-form/NaVaForm';
import NaVaFormStatePuller from '../../../common/forms/NaVaFormStatePuller';
import { NaVaFormContextType, NaVaFormValues } from '../../../common/forms/validation/na-va-form/types';
import Input from '../../../common/forms/validation/controls/input/Input';
import { FIELD_DESIGNATION } from '../../../data/field-constants/GeneralConstants';
import { getIn } from '../../../common/forms/validation/na-va-form/commonUtils';
import { L } from '../../../abp/utils';
import { useDebounce } from 'usehooks-ts';

const JobManagement: React.FC = () => {
    const [{ values }, setFormState] = useState<NaVaFormContextType<NaVaFormValues>>({} as any);
    const designation = getIn(values, FIELD_DESIGNATION);
    const debouncedDesignation = useDebounce<string>(designation, 1500);

    const controlStateConfig = useMemo(() => {
        return {
            ...defaultPlmControlState,
            job: {
                ...defaultPlmControlState.job,
                filter: {
                    ...defaultPlmControlState.job.filter,
                    internalFilters: [...defaultPlmControlState.job.filter?.internalFilters ?? [], filter(FIELD_DESIGNATION).contains(debouncedDesignation)]
                }
            }
        }
    }, [debouncedDesignation]);

    return <div className="test-new-ui row p-4 my-3">

        <NaVaForm
            initialValues={{
            }}
            onSubmit={() => { }}
        >
            <NaVaFormStatePuller onStateChanged={v => setFormState(v)}></NaVaFormStatePuller>

            <div className="my-3">
                <Input name={FIELD_DESIGNATION} label={L('Designation')} sm={6} md={3} />
            </div>
        </NaVaForm>

        <PlmJobList columnConfig={defaultColumnConfig} controlStateConfig={controlStateConfig} readOnly={false} selectable={true} showTemplates={false} listProps={{ enableAttachments: true }} />
    </div>
}

export default JobManagement;