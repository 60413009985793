import { DateTime } from "luxon";
import { asLocalizedSelectionValues, jobTypeJobJournalLineLedgerEntryType, jobTypeResourceJournalLineLedgerEntryType, jobTypeType } from "./columnConfigurations";
import { FIELD_END_DATE, FIELD_EXTENSIONS, FIELD_START_DATE, FIELD_USE_DURATION } from '../../../data/field-constants/GeneralConstants';
import { FIELD_EXTENSION_DATA } from "../../../util/EntityUtils";

const getSelectionValue = (collection: string[], key: string) => {
    return asLocalizedSelectionValues(collection).find(x => x.key === key);
}

export const JobTypeDefaultValues = {
    'type': 'Lump-sum-settlem.', //getSelectionValue(jobTypeType, 'Lump-sum-settlem.'),
    'resourceJobJournalLineJobLedgerEntryType': 'Usage',// getSelectionValue(jobTypeJobJournalLineLedgerEntryType, 'Usage'),
    'resourceJobJournalLineLedgerEntryType': 'Usage',// getSelectionValue(jobTypeResourceJournalLineLedgerEntryType, 'Usage'),
    'itemJobJournalLineJobLedgerEntryType': 'Usage',// getSelectionValue(jobTypeJobJournalLineLedgerEntryType, 'Usage'),
    'glAccountJobJournalLineJobLedgerEntryType': 'Usage',//getSelectionValue(jobTypeJobJournalLineLedgerEntryType, 'Usage'),
    'itemJobJournalLineLedgerEntryType': 'Negative Adjmt.',// getSelectionValue(jobTypeJobJournalLineLedgerEntryType, 'Negative Adjmt.'),
    'allowBlankJobJournalLine': true,
    'allowBillableJobJournalLine': true,
    'allowBudgetJobPlanningLine': true,
    'allowBillableJobPlanningLine': true,
    'allowBudgetBillableJobPlanningLine': true,
    'salesQuoteToOrder': true,
    // Adjustments
    'positiveUseDimensions': 'Dimensions from Job Task',
    'negativeUseDimensions': 'Dimensions from Job Task'
}

export const JobDefaultExtensions = {
    'organisationAttributeCode': '20',
    'sellToCustomerNumber': '99999'
}

export const JobDefaultValues = {
    [FIELD_START_DATE]: DateTime.now().startOf('month').toISO(),
    [FIELD_END_DATE]: DateTime.now().endOf('month').toISO(),
    [FIELD_EXTENSIONS]: JobDefaultExtensions,
    [FIELD_EXTENSION_DATA]: JSON.stringify(JobDefaultExtensions),
    'customState': 'Planning'
};

const JobTaskDefaultExtensions = {
    'directTransfer': true,
    'useQuantity': 'Fix Quantity with Multiplier',
    'autoTransferOnStatusChange': 'CreateItemReclassJournalAndPost',
    'autoTransferForStatus': 'Done',
    'itemReclassJnlTemplateName': 'Umlagerung',    
    'itemReclassJnlBatchName': 'ABPL',  
}

export const JobTaskDefaultValues = {
    // [FIELD_EXTENSIONS]: JobTaskDefaultExtensions,    
}


export const JobPlanningLineValues = {
    [FIELD_START_DATE]: DateTime.now().startOf('day').toISO(),
    [FIELD_USE_DURATION]: true
}

export const ItemDefaultValues = {
    
}