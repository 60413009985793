import { useCallback, useEffect, useMemo, useState } from "react";
import ExpansionPanel from "../../../../common/components/expansion-panel/ExpansionPanel";
import NaVaFormStatePuller from "../../../../common/forms/NaVaFormStatePuller";
import NaVaForm from "../../../../common/forms/validation/na-va-form/NaVaForm";
import { NaVaFormContextType, NaVaFormValues } from "../../../../common/forms/validation/na-va-form/types";
import { parseTemplateValueString } from "../../../../common/components/template-input/TemplateInput";
import TemplateField, { parseTemplateString } from '../../../../common/components/template-input/TemplateField';
import { TemplateConfig } from "../types";
import { L } from "../../../../abp/utils";
import _ from "lodash";
import { getIn } from "../../../../common/forms/validation/na-va-form/commonUtils";
import Input from "../../../../common/forms/validation/controls/input/Input";
import Switch from "../../../../common/forms/validation/controls/switch/Switch";
import filter from "../../../../util/FilterUtils";
import { FIELD_EXTENSION_DATA, getExtensionValue } from "../../../../util/EntityUtils";
import PlmControl from "../plm-job-list/PlmControl";
import { defaultColumnConfig } from "../../data/columnConfigurations";
import { defaultPlmControlState } from "../plm-job-list/components/PlmControlComponents";
import { FIELD_ID } from "../../../../data/field-constants/GeneralConstants";
import Button from "../../../../common/forms/controls/button/Button";
import useApi from "../../../../common/hooks/useApi";
import { SdtTemplateApi } from "../../../../client/http";
import { showNotification } from "../../../../common/components/notifications/NotificationHost";
import { FIELD_TEMPLATE_ID } from "../../../../data/field-constants/ReportOptionConstants";
import HashColorGenerator, { ColorGeneratorResult } from "../../../../util/HashColorGenerator";

const makeTemplateConfigIdentifier = (config: TemplateConfig) => `${config.entityType}_${config.name}`;

const TemplateInputEditorField: React.FC<{ config: TemplateConfig, groupedColoredTemplateFields: GroupedTemplateFieldsWithColors }> = ({ config, groupedColoredTemplateFields }) => {

    const { name, type } = config;
    const { entityType: __, ...templateFieldProps } = config;

    const groupColor = Object.values(groupedColoredTemplateFields).find(e => e.fields.includes(name))?.color;
    const input = useCallback(() => {
        switch (type) {
            case 'text':
            case 'number':
            case 'date':
                return (
                    <Input
                        type={type}
                        placeholder="Default"
                        name={name}
                        ml={3}
                        style={{ border: `4px solid ${groupColor?.hex}`, backgroundColor: groupColor?.hex }}
                        className="k-rounded-md"
                    />
                );
            case 'boolean':
                return (
                    <Switch
                        name={name}
                        ml={3}
                    />
                );
            default:
                return null;
        }
    }, [type, name, groupColor]);

    return <div className="col-12 col-md-6 col-lg-4 col-xl-3 col-xxl-2 mb-3 d-flex align-items-center flex-grow-1 flex-shrink-1 template-editor-field">
        <TemplateField data={templateFieldProps as any} disabled={true} id={makeTemplateConfigIdentifier(config)} />
        {input()}
    </div>
};

interface GroupedTemplateFields {
    [key: string]: string[];
}

interface GroupedTemplateFieldsWithColors {
    [key: string]: {
        fields: string[];
        color: ColorGeneratorResult;
    };
}

const groupTemplateFieldsByPrefix = (fields: string[]): GroupedTemplateFields => {
    // Initialize result object
    const grouped: GroupedTemplateFields = {
        noPrefix: [] // Default group for items without prefix
    };

    // Helper function to detect prefix
    function detectPrefix(field: string): string {
        // Common prefixes we want to detect
        // ^[A-Z]                     - Starts with capital letter
        // (?:                        - Non-capturing group
        //     [a-z0-9]+(?=[A-Z])    - Traditional prefix (like "Pulv")
        //     |                      - OR
        //     (?=[A-Z][a-z])        - Single letter prefix followed by capital word
        // )
        const prefixRegex = /^([A-Z](?:[a-z0-9]+(?=[A-Z])|(?=[A-Z][a-z])))/;
        const match = field.match(prefixRegex);
        return match ? match[1] : 'noPrefix';
    }

    // Process each field
    fields.forEach(field => {
        const prefix = detectPrefix(field);

        // Initialize array for new prefix if it doesn't exist
        if (!grouped[prefix]) {
            grouped[prefix] = [];
        }

        // Add field to appropriate group
        grouped[prefix].push(field);
    });

    // Clean up: remove empty groups
    Object.keys(grouped).forEach(key => {
        if (grouped[key].length === 0) {
            delete grouped[key];
        }
    });

    return grouped;
}


function groupTemplateFieldsWithColors(fields: string[]): GroupedTemplateFieldsWithColors {
    const grouped = groupTemplateFieldsByPrefix(fields);
    const result: GroupedTemplateFieldsWithColors = {};
    const hashColorGenerator = new HashColorGenerator({ salt: 0.5, saturation: 0.9 })

    Object.entries(grouped).forEach(([prefix, fields]) => {
        result[prefix] = {
            fields,
            color: hashColorGenerator.generateColorForPrefix(prefix)
        };
    });

    return result;
}

const TemplateFieldManagement: React.FC<{ dataItem: any, onDataChanged: (data: any) => void }> = ({ dataItem, onDataChanged }) => {

    const [data, setData] = useState([{ ...dataItem }]);
    const [templateFields, setTemplateFields] = useState<TemplateConfig[]>([]);
    const [groupedColoredTemplateFields, setGroupedColoredTemplateFields] = useState<GroupedTemplateFieldsWithColors>({});

    useEffect(() => {
        if (!dataItem) return;

        getExtensionValue(dataItem, '');

        let templateStrings = [] as string[];

        Object.entries(dataItem).map(([key, val]) => {
            if (key === FIELD_EXTENSION_DATA) return null;

            const templateItems = parseTemplateValueString(val as any)?.filter(x => x.type === 'template').map(i => i.value);
            if (templateItems?.length) {
                templateStrings = _.uniq([...templateStrings, ...templateItems]);
            }
            return null;
        });

        let fields = templateStrings.map(i => ({ ...parseTemplateString(i), entityType: 'job' }) as TemplateConfig);

        setTemplateFields(fields);
        setGroupedColoredTemplateFields(groupTemplateFieldsWithColors(fields.map(x => x.name)));

        setData([{ ...dataItem }]);

    }, [dataItem]);

    const [{ values, setFieldValue }, setFormState] = useState<NaVaFormContextType<NaVaFormValues>>({} as any);

    // useEffect(() => {

    //     if (!values || !Object.keys(values)?.length || data.length < 1) return;

    //     const replacements = {} as { [id: string]: any };

    //     Object.entries(values).filter(([__, val]) => !!val).forEach(([key, val]) => {
    //         replacements[key] = val;
    //     });

    //     const newData = data.slice() as [{ [id: string]: any }];
    //     const plainData = [{ ...data[0] }] as [{ [id: string]: any }];//data.slice()

    //     Object.entries(dataItem).forEach(([key, val]) => {
    //         if (val != null && val !== undefined && typeof val !== 'object' && typeof val !== 'function' && key !== FIELD_EXTENSION_DATA) {

    //             const templateItems = parseTemplateValueString(val as string)
    //                 .map(i => {
    //                     if (i.type === "template") {
    //                         const tempData = parseTemplateString(i.value);

    //                         if (Object.keys(replacements).includes(tempData.name)) {
    //                             return {
    //                                 ...i,
    //                                 value: makeTemplateString({
    //                                     ...tempData, name: '',
    //                                     defaultValue: getIn(replacements, tempData.name)
    //                                 })
    //                             };
    //                         }
    //                     }

    //                     return i;
    //                 });

    //             newData[0][key] = makeTemplateValueString(templateItems);
    //             plainData[0][key] = makePlainValueString(templateItems);
    //         }
    //     });

    //     setData(newData);
    //     onDataChanged(plainData);

    // }, [values, dataItem])


    // const [{ applyMethod, methods }, setMethodContextState] = useState<MethodContextType>({} as any);

    // const getTemplateFieldNamesMethods = getIn(methods, PlmControlMethods.getTemplateFieldNames);
    // useEffect(() => {
    //     if (!applyMethod) return;
    //     console.log("methods have updated ", methods);

    //     const fields = applyMethod(PlmControlMethods.getTemplateFieldNames, [] as TemplateConfig[]);
    //     console.log("template fields are ", fields);

    //     setTemplateFields(fields ?? [] as TemplateConfig[]);
    // }, [applyMethod, dataItem, getTemplateFieldNamesMethods])
    const dataId = getIn(data[0], FIELD_ID);

    const controlStateConfig = useMemo(() => {
        return {
            ...defaultPlmControlState,
            job: {
                ...defaultPlmControlState.job,
                filter: {
                    ...defaultPlmControlState.job.filter,
                    internalFilters: [...defaultPlmControlState.job.filter?.internalFilters ?? [], filter(FIELD_ID).contains(dataId)]
                }
            }
        }
    }, [dataId]);

    const existingJobsControlStateConfig = useMemo(() => {
        return {
            ...defaultPlmControlState,
            job: {
                ...defaultPlmControlState.job,
                filter: {
                    ...defaultPlmControlState.job.filter,
                    internalFilters: [...defaultPlmControlState.job.filter?.internalFilters ?? [], filter(FIELD_TEMPLATE_ID).contains(dataId)]
                }
            }
        }
    }, [dataId]);

    const [, templateApi] = useApi<any, SdtTemplateApi>(c => new SdtTemplateApi(c));

    useEffect(() => {
        const fetchTemplateFields = async () => {
            if (!templateApi || !dataId) return;

            const fieldResult = await templateApi?.apiServicesModulesSdtPlmSdtTemplateGetDistinctTemplateFieldsGet(dataId);
            const fields = _.orderBy((fieldResult as any).result as TemplateConfig[], ['name'], ['asc']);

            setTemplateFields(fields);
            setGroupedColoredTemplateFields(groupTemplateFieldsWithColors(fields.map(x => x.name)));
        };
        fetchTemplateFields();
    }, [dataId, templateApi]);

    const templateReplacementsValid = useMemo(() => {
        return _.every(templateFields.map(t => t.name), (k => {
            const val = getIn(values, k);
            console.log(k, val);
            return val !== undefined && val != null && !!val;
        }));
    }, [values, templateFields])

    const applyTemplate = useCallback(async () => {
        if (!templateApi || !dataId) return;

        if (!window.confirm("Diese Funktion wendet die Vorlage an und erzeugt ein neues Projekt mit entsprechenden untergeordneten Datensätzen." +
            "Dieser Prozess kann einige Zeit in Anspruch nehmen - Möchten Sie fortfahren?")) return;

        try {
            const newId = await templateApi?.apiServicesModulesSdtPlmSdtTemplateCreateFromTemplatePost({
                templateJobId: dataId,
                replacementValues: values
            });

            showNotification('Vorlage anwenden erfolgreich', 'Vorlage erfolgreich angewendet', 'success', 5000, true);
        } catch (error) {
            showNotification('Vorlage anwenden fehlgeschlagen', 'Fehler beim Anwenden der Vorlage aufgetreten', 'error', undefined, true);
        }
    }, [templateApi, dataId, values]);

    console.log(groupedColoredTemplateFields);

    return <div className="template-fields">

        {/* <h4>Vorlagenfelder</h4> */}

        <NaVaForm
            initialValues={{
            }}
            onSubmit={() => { }}
        >
            <NaVaFormStatePuller onStateChanged={v => setFormState(v)}></NaVaFormStatePuller>

            <ExpansionPanel initialExpanded={true} title={L('TemplateFields')}>
                <div className="row">
                    {templateFields.map(f => {
                        return <TemplateInputEditorField config={f} key={makeTemplateConfigIdentifier(f)} groupedColoredTemplateFields={groupedColoredTemplateFields} />
                    })}
                </div>
            </ExpansionPanel>

            {/* <ExpansionPanel initialExpanded={true} title={L('Job')}>
                <div className="row">
                    {templateFields.filter(f => f.entityType === 'job').map(f => {
                        return <TemplateInputEditorField config={f} key={makeTemplateConfigIdentifier(f)} />
                    })}
                </div>
            </ExpansionPanel>
            <ExpansionPanel initialExpanded={true} title={L('JobTask')}>
                <div className="row">
                    {templateFields.filter(f => f.entityType === 'jobTask' || f.entityType === 'jobTaskTransferLines').map(f => {
                        return <TemplateInputEditorField config={f} key={makeTemplateConfigIdentifier(f)} />
                    })}
                </div>
            </ExpansionPanel>
            <ExpansionPanel initialExpanded={true} title={L('JobType')}>
                <div className="row">
                    {templateFields.filter(f => f.entityType === 'jobType' || f.entityType === 'jobTypeLines').map(f => {
                        return <TemplateInputEditorField config={f} key={makeTemplateConfigIdentifier(f)} />
                    })}
                </div>
            </ExpansionPanel>
            <ExpansionPanel initialExpanded={true} title={L('JobPlanningLine')}>
                <div className="row">
                    {templateFields.filter(f => f.entityType === 'jobPlanningLines').map(f => {
                        return <TemplateInputEditorField config={f} key={makeTemplateConfigIdentifier(f)} />
                    })}
                </div>
            </ExpansionPanel> */}


        </NaVaForm>

        <h5>{L('Job')}</h5>

        {/* <ModuleComponentProvider appPartName={AppParts.ServiceEntities.Grid.Job} getSingleComponent={true}
            props={{
                isEditable: false,
                isRefetchable: false,
                data,
                cells: {
                    edit:
                    {
                        text: TemplateInputGridCell
                    }
                    , data: TemplateDataGridCell
                }, selectable: {
                    enabled: false
                }
            } as JobGridProps} /> */}
        {dataId &&
            <PlmControl type="Job" columnConfig={defaultColumnConfig} showTemplates selectable={false} readOnly={true} replacements={values}
                controlStateConfig={controlStateConfig} >
                {/* <MethodContextPuller onContextChanged={setMethodContextState} /> */}
            </PlmControl>
        }

        <Button color="primary" label="Vorlage anwenden" onClick={applyTemplate} disabled={!templateReplacementsValid} my={3} sm={6} offsetSm={3} md={4} offsetMd={4} />

        <ExpansionPanel title={L('Existing Jobs')}>
            {dataItem && getIn(dataItem, FIELD_ID) &&
                <PlmControl type="Job" columnConfig={defaultColumnConfig} showTemplates={false} selectable={false} readOnly={true} replacements={values}
                    controlStateConfig={existingJobsControlStateConfig} constrainToList>
                    {/* <MethodContextPuller onContextChanged={setMethodContextState} /> */}
                </PlmControl>
            }

        </ExpansionPanel>

    </div>
}

export default TemplateFieldManagement;