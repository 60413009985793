import React from "react";
import { colRo } from "../data/columnConfigurations";
import { FIELD_DESCRIPTION1, FIELD_DESCRIPTION2, FIELD_NUMBER } from "../../../data/field-constants/GeneralConstants";
import { getIn } from "../../../common/forms/validation/na-va-form/commonUtils";
import { CellRender } from "../template-management/plm-job-list/components/PlmControlComponents";
import { getExtensionValue } from "../../../util/EntityUtils";

const templatedItemItemRenderColumns = [
    colRo(FIELD_NUMBER, undefined, undefined, undefined, 'data'),  //number "",
    colRo(FIELD_DESCRIPTION1, undefined, undefined, undefined, 'data'),  //displayName "",
    colRo(FIELD_DESCRIPTION2, undefined, undefined, undefined, 'data'),
]

export const ItemItemRender = (li: any, itemProps: any) => {
    var parsed = itemProps.dataItem;
    parsed.extensions = JSON.parse(parsed.extensionData ?? '{}');

    const itemChildren = (
        <div className={"combo-box-item"}>
            <span className="number">{getIn(parsed, FIELD_NUMBER)}</span>
            <span className="description1">{getIn(parsed, FIELD_DESCRIPTION1)}</span>
            <span className="description2">{getIn(parsed, FIELD_DESCRIPTION2)}</span>        
        </div >
    );
    return React.cloneElement(li, li.props, itemChildren);
};

export const TemplatedItemItemRender = (li: any, itemProps: any) => {
    var item = itemProps.dataItem;

    const itemChildren = (
        <div className={"combo-box-item"}>
            {templatedItemItemRenderColumns.map(c => {
                return <CellRender columnConfig={c} value={c.isExtensionField ? getExtensionValue(item, c?.name) : getIn(item, c?.name)}
                    onChange={() => { }} isEditMode={false} />
            })}
        </div >
    );
    return React.cloneElement(li, li.props, itemChildren);
};