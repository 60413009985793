import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useNaVaFormContext } from '../../../common/forms/validation/na-va-form/NaVaFormContext';
import { getIn } from '../../../common/forms/validation/na-va-form/commonUtils';
import { FIELD_IS_EDITABLE, FIELD_IS_PLANNING_ENTITY, FIELD_JOB_TASK, FIELD_JOB_TASK_ID, FIELD_ORIG_BLANKET_RES_QTY, FIELD_RES_QTY_SALE, FIELD_RES_QTY_SCHEDULE, FIELD_RES_QTY_USAGE } from '../../../data/field-constants/GeneralConstants';
import { IsEmptyGuid, NullGuid } from '../../../util/Values';
import { Chart, ChartArea, ChartLegend, ChartSeries, ChartSeriesItem, ChartTooltip } from '@progress/kendo-react-charts';
import { L } from '../../../abp/utils';

import './BudgetWidget.scss';
import { JobTask, TimeEntitiesApi, TimeEntity } from '../../../client/http';
import { getExtensionValue } from '../../../util/EntityUtils';
import useApi from '../../../common/hooks/useApi';
import { Settings_ProviderName, Settings_ServiceConnectionId } from '../../../util/SettingNames';
import filter from '../../../util/FilterUtils';
import classNames from 'classnames';
import { FIELD_LINE_TYPE } from '../field-constants/BcConstants';
import { TimeEntityCreatedOrUpdated } from '../../../scenes/time-tracking/time-entities/time-entity-input/TimeEntityInput2';

const BudgetWidget: React.FC = () => {

    const { values } = useNaVaFormContext();

    const jobTask = getIn(values, FIELD_JOB_TASK) as JobTask;
    const jobTaskId = jobTask?.id;

    const [, timeEntitiesApi] = useApi<TimeEntity, TimeEntitiesApi>(c => new TimeEntitiesApi(c));

    const [unexportedEntries, setUnexportedEntries] = useState<TimeEntity[] | null>()

    const retrieveExistingEntries = useCallback(async () => {
        const data = await timeEntitiesApi?.apiServicesAppTimeEntitiesGetAllEntitiesGet(abp.setting.get(Settings_ProviderName), abp.setting.getInt(Settings_ServiceConnectionId),
            undefined, undefined, JSON.stringify([filter(FIELD_JOB_TASK_ID).equals(jobTaskId!), filter(FIELD_IS_PLANNING_ENTITY).equals(false), filter(FIELD_IS_EDITABLE).equals(true)]));

        setUnexportedEntries((data as any).result);
    }, [jobTaskId, timeEntitiesApi]);

    const retrieveExistingEntriesOnEvent = useCallback(() => {
        setTimeout(() => retrieveExistingEntries(), 2000);
    }, [retrieveExistingEntries]);

    useEffect(() => {
        abp.event.on(TimeEntityCreatedOrUpdated, retrieveExistingEntriesOnEvent);

        return () => abp.event.off(TimeEntityCreatedOrUpdated, retrieveExistingEntriesOnEvent);
    });

    useEffect(() => { jobTaskId && jobTaskId !== NullGuid && retrieveExistingEntries() }, [jobTaskId, retrieveExistingEntries]);

    const unexportedUsageEntries = unexportedEntries?.filter(e => getExtensionValue(e, FIELD_LINE_TYPE) === '0');
    const unexportedSalesEntries = unexportedEntries?.filter(e => getExtensionValue(e, FIELD_LINE_TYPE) !== '0');

    const unexportedUsageAmount = unexportedUsageEntries?.reduce((sum, curr) => sum + (curr?.amount ?? 0), 0) ?? 0.0;
    const unexportedSalesAmount = unexportedSalesEntries?.reduce((sum, curr) => sum + (curr?.amount ?? 0), 0) ?? 0.0;

    const origBlanketResQty: number = getExtensionValue(jobTask, FIELD_ORIG_BLANKET_RES_QTY);
    const resQtySchedule: number = getExtensionValue(jobTask, FIELD_RES_QTY_SCHEDULE);
    const resQtyUsage: number = getExtensionValue(jobTask, FIELD_RES_QTY_USAGE);
    const resQtySale: number = getExtensionValue(jobTask, FIELD_RES_QTY_SALE);
    const available = origBlanketResQty - (resQtySale + unexportedSalesAmount);
    const unused = origBlanketResQty - (resQtyUsage + unexportedUsageAmount);
    const data = useMemo(() => [{
        name: 'planning',
        data: [
            {
                category: L('Ordered'),
                value: origBlanketResQty,
                color: "var(--primary-200)"
            }
        ]
    }, {
        name: 'usage',
        data: [
            {
                category: L('Used'),
                value: resQtyUsage,
                color: "var(--primary-400)"
            },
            {
                category: `${L('Unreleased')} (${L('Usage')})`,
                value: unexportedUsageAmount,
                color: "var(--primary-500)"
            },
            {
                category: unused > 0 ? `${L('Available')} (${L('Usage')})` : L('Overrun'),
                value: Math.abs(unused),
                color: unused > 0 ? "#ddd" : "var(--bs-danger)"
            },
        ]
    }, {
        name: 'sales',
        data: [
            {
                category: L('Sold'),
                value: resQtySale,
                color: "var(--primary-600)"
            },
            {
                category: `${L('Unreleased')} (${L('Sale')})`,
                value: unexportedSalesAmount,
                color: "var(--primary-700)"
            },
            {
                category: available > 0 ? `${L('Available')} (${L('Sale')})` : L('Overrun'),
                value: Math.abs(available),
                color: available > 0 ? "#ddd" : "var(--bs-danger)"
            },
        ]
    }], [available, origBlanketResQty, resQtySale, resQtyUsage, unexportedSalesAmount, unexportedUsageAmount, unused]);

    const donutCenterRenderer = useCallback(() => (
        <div className="row align-items-center">
            <div className="col-auto align-items-center">
                <h3 className={classNames('my-0', { 'text-danger': available < 0 }, { 'text-warning': unused < 0 && available > 0 })}>{available} {L('Unit_Hour_Text')}</h3>
                <div className="text-center">
                    {L('Available')}
                </div>
            </div>
            <div className="col-auto">
                <h3 className="my-0">{resQtySale + unexportedSalesAmount} {L('Unit_Hour_Text')}</h3>
                <div className="text-center">
                    {L('Tracked')}
                </div>
            </div>
            <div className="col-auto quick-overview">
                <div>
                    <div className="badge color-swatch" style={{ backgroundColor: 'var(--primary-400)' }} />
                    {L('Sold')}: {data.find(x => x.name === 'sales')?.data[0].value.toLocaleString()} {L('Unit_Hour_Text')}
                </div>
                <div>
                    <div className="badge color-swatch" style={{ backgroundColor: 'var(--primary-600)' }} />
                    {L('Used')}: {data.find(x => x.name === 'usage')?.data[0].value.toLocaleString()} {L('Unit_Hour_Text')}
                </div>
            </div>
            <div className="col-auto quick-overview">
                <div>
                    <div className="badge color-swatch" style={{ backgroundColor: 'var(--primary-200)' }} />
                    {L('Ordered')}: {data.find(x => x.name === 'planning')?.data[0].value.toLocaleString()} {L('Unit_Hour_Text')}
                </div>
                <div>
                    <div className="badge color-swatch" style={{ background: 'linear-gradient(to right, var(--primary-500) 0%, var(--primary-500) 49%, var(--primary-700) 50%, var(--primary-700) 100%)' }} />
                    {L('Unreleased')}: {unexportedUsageAmount + unexportedSalesAmount} {L('Unit_Hour_Text')}
                </div>
            </div>
        </div>
    ), [available, data, resQtySale, unexportedSalesAmount, unexportedUsageAmount, unused]);

    const labelContent = (e: any) => `${e.category}: \n ${e.value} Std. (${origBlanketResQty === 0 ? 0 : e.value / origBlanketResQty * 100})`;

    const mapSeries = (series: any, index: number, array: any[]) => (
        <ChartSeriesItem
            type="donut"
            startAngle={150}
            name={series.name}
            data={series.data}
            field="value"
            categoryField="category"
            colorField="color"
        >
        </ChartSeriesItem>
    );
    const renderTooltip = useCallback((context: any) => {
        const { category, value } = context.point || context;
        return (
            <div>
                {`${category}: \n ${value.toLocaleString()} Std. (${origBlanketResQty === 0 ? 0 : (value / origBlanketResQty * 100).toFixed(0)}%)`}
            </div>
        );
    }, [origBlanketResQty]);


    return useMemo(() => jobTask && !IsEmptyGuid(jobTask?.id) ?
        <div className="budget-widget row align-items-center">
            <div className="col-auto">
                <Chart style={{ width: 100, height: 100 }}>
                    <ChartTooltip render={renderTooltip} />
                    <ChartLegend visible={false} />
                    <ChartArea background="none" />
                    <ChartSeries>{data.map(mapSeries)}</ChartSeries>
                </Chart>
            </div>
            <div className="col-auto">
                {donutCenterRenderer()}
            </div>
        </div>
        : null
        , [jobTask, data, donutCenterRenderer, renderTooltip]);
}

export default BudgetWidget;