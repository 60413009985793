import { useCallback, useEffect, useMemo, useState } from "react";
import { Configuration } from "../../client/http";
import { DataViewMessageProps } from "../components/data-view-message/DataViewMessage";
import useAuthConfiguration from "./useAuthConfiguration";
import useDataLoadingState from "./useDataLoadingState";
import { useAuthenticator } from "../../authentication/authenticator/Authenticator";
import { error, log } from "../../util/LoggingUtils";

// data, apiClient, create, get, update, remove, dataViewMessageData, refetch, totalLength
export type UseCrudApiResult<T, TApi> = [
    T[], TApi | undefined,
    ((client: TApi, data: T) => Promise<any>) | undefined,
    ((client: TApi, id: string) => Promise<any>) | undefined,
    ((client: TApi, data: T) => Promise<any>) | undefined,
    ((client: TApi, data: T) => Promise<any>) | undefined,
    DataViewMessageProps | undefined,
    (loadInBackground?: boolean) => void,
    number | undefined];

/**
 * Used to allow a standardized access to a generic api from a reusable component 
 * @returns [data, apiClient, create, get, update, remove, dataViewMessageData, refetch, totalLength]
 */
export default function useCrudApi<T, TApi>(
    apiFactory: (config: Configuration) => TApi,
    getAll?: (client: TApi) => Promise<any>,
    create?: (client: TApi, data: T) => Promise<any>,
    get?: (client: TApi, id: string) => Promise<any>,
    update?: (client: TApi, data: T) => Promise<any>,
    remove?: (client: TApi, data: T) => Promise<any>,
    mapFunction: (value: T, index: number, array: T[]) => T = (val) => val
): UseCrudApiResult<T, TApi> {

    const authConfig = useAuthConfiguration();
    const [data, setData] = useState<T[]>([]);
    const apiClient = useMemo(() => authConfig && apiFactory(authConfig), [authConfig]);
    const [dataLoading, dataLoaded, dataLoadError, , dataViewMessageData] = useDataLoadingState();
    const { isCurrentlyAuthenticated, refreshToken } = useAuthenticator();
    const [totalCount, setTotalCount] = useState<number | undefined>(undefined);

    const fetch = useCallback((loadInBackground?: boolean) => {
        log("api updated");
        if (apiClient && getAll && mapFunction) {
            if (!isCurrentlyAuthenticated) {
                refreshToken(abp.session.tenantId ?? null);
                return;
            }
            !loadInBackground && dataLoading();
            getAll(apiClient).then((val: any) => {
                const data = val.result ? (val.result.map ? val.result.map(mapFunction) : (val.result.items?.map ? val.result.items.map(mapFunction) : val.result)) : val.result;
                if (val?.result?.totalCount) {
                    setTotalCount(val.result.totalCount);
                }
                else {
                    setTotalCount(undefined);
                }
                setData(data);
                !loadInBackground && dataLoaded(data);
            }).catch((e: any) => {
                error("Error fetching data.", e);
                !loadInBackground && dataLoadError((e as any)?.error?.message ?? (e as any).statusText ?? '');
            });
        }

    }, [apiClient, dataLoading, dataLoaded, dataLoadError, isCurrentlyAuthenticated, refreshToken]);

    useEffect(() => { fetch() }, [fetch])

    return [data, apiClient, create, get, update, remove, dataViewMessageData, fetch, totalCount];
}

/**
 * Used to allow a standardized access to a generic api from a reusable component.
 * 
 * This is a reactive version which will react to a change of the input parameters
 * @returns [data, apiClient, create, get, update, remove, dataViewMessageData, refetch, totalLength]
 */
export function useReactiveCrudApi<T, TApi>(
    apiFactory: (config: Configuration) => TApi,
    getAll?: (client: TApi) => Promise<any>,
    create?: (client: TApi, data: T) => Promise<any>,
    get?: (client: TApi, id: string) => Promise<any>,
    update?: (client: TApi, data: T) => Promise<any>,
    remove?: (client: TApi, data: T) => Promise<any>,
    mapFunction?: (value: T, index: number, array: T[]) => T
): UseCrudApiResult<T, TApi> {

    const authConfig = useAuthConfiguration();
    const [data, setData] = useState<T[]>([]);
    const apiClient = useMemo(() => authConfig && apiFactory(authConfig), [authConfig, apiFactory]);
    const [dataLoading, dataLoaded, dataLoadError, , dataViewMessageData] = useDataLoadingState();
    const defaultMapFunction = useCallback((val: T) => val, []);
    const { isAuthenticated, refreshToken } = useAuthenticator();
    const [totalCount, setTotalCount] = useState<number | undefined>(undefined);

    if (mapFunction === undefined)
        mapFunction = defaultMapFunction;


    const fetch = useCallback((loadInBackground?: boolean) => {
        log("reactive api updated");
        if (apiClient && getAll) {
            if (!isAuthenticated) {
                refreshToken(abp.session.tenantId ?? null);
                return;
            }
            !loadInBackground && dataLoading();
            getAll(apiClient)
                .then((val: any) => {
                    const data = val.result ? (val.result.map ? val.result.map(mapFunction) : (val.result.items?.map ? val.result.items.map(mapFunction) : val.result)) : val.result;
                    if (val?.result?.totalCount) {
                        setTotalCount(val.result.totalCount);
                    }
                    else {
                        setTotalCount(undefined);
                    }
                    setData(data);
                    !loadInBackground && dataLoaded(data);
                }).catch((e: any) => {
                    error("Error fetching data.", e);
                    !loadInBackground && dataLoadError((e as any)?.error?.message ?? (e as any).statusText ?? '');
                });
        }

    }, [apiClient, getAll, mapFunction, dataLoading, dataLoaded, dataLoadError, isAuthenticated, refreshToken]);

    useEffect(() => { fetch() }, [fetch]);

    return [data, apiClient, create, get, update, remove, dataViewMessageData, fetch, totalCount];
}