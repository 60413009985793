export interface ColorGeneratorOptions {
    salt?: number;             // Base shift for all colors (0-1)
    saturation?: number;       // 0-1
    lightness?: number;        // 0-1
    minColorDistance?: number  // 0-1
}


class HashColorGenerator {
    private static readonly GOLDEN_RATIO = 0.618033988749895;
    private static readonly DEFAULT_OPTIONS: Required<ColorGeneratorOptions> = {
        salt: 0,
        saturation: 0.7,
        lightness: 0.75,
        minColorDistance: 0.1  // Minimum distance between colors (0-1)
    };

    private usedHues: number[] = [];
    private options: Required<ColorGeneratorOptions>;

    constructor(options: ColorGeneratorOptions = {}) {
        this.options = {
            ...HashColorGenerator.DEFAULT_OPTIONS,
            ...options
        };
        this.options.salt = ((this.options.salt % 1) + 1) % 1;
    }

    private hashString(str: string): number {
        let hash = 0;
        for (let i = 0; i < str.length; i++) {
            const char = str.charCodeAt(i);
            hash = ((hash << 5) - hash) + char;
            hash = hash & hash;
        }
        return Math.abs(hash) / Math.pow(2, 32); // Normalize to 0-1
    }

    private getHueDistance(hue1: number, hue2: number): number {
        const diff = Math.abs(hue1 - hue2);
        return Math.min(diff, 1 - diff);
    }

    private findBestHue(baseHue: number): number {
        if (this.usedHues.length === 0) {
            this.usedHues.push(baseHue);
            return baseHue;
        }

        // Try multiple candidates and pick the one with maximum distance to existing colors
        const candidates: number[] = [];
        const steps = 8; // Number of candidates to try
        
        for (let i = 0; i < steps; i++) {
            // Generate candidates using golden ratio distribution
            candidates.push((baseHue + (i * HashColorGenerator.GOLDEN_RATIO)) % 1);
        }

        let bestHue = baseHue;
        let maxMinDistance = 0;

        candidates.forEach(candidate => {
            // Find minimum distance to any existing color
            const minDistance = Math.min(...this.usedHues.map(
                existingHue => this.getHueDistance(candidate, existingHue)
            ));

            if (minDistance > maxMinDistance) {
                maxMinDistance = minDistance;
                bestHue = candidate;
            }
        });

        // If colors are too similar, shift the hue further
        if (maxMinDistance < this.options.minColorDistance) {
            bestHue = (bestHue + HashColorGenerator.GOLDEN_RATIO) % 1;
        }

        this.usedHues.push(bestHue);
        return bestHue;
    }

    public generateColorForPrefix(prefix: string): ColorGeneratorResult {
        const hash = this.hashString(prefix);
        
        // Initial hue based on hash and salt
        let baseHue = (hash + this.options.salt) % 1;
        
        // Find the best hue that maintains good distance from existing colors
        let hue = this.findBestHue(baseHue);

        // Generate the color
        const rgb = this.hslToRgb(
            hue,
            this.options.saturation,
            this.options.lightness
        );
        
        const hex = this.rgbToHex(rgb.r, rgb.g, rgb.b);
        const contrast = this.getContrastColor(rgb.r, rgb.g, rgb.b);

        return { 
            hex, 
            rgb, 
            contrast,
            hue // Adding hue for debugging/visualization
        };
    }

    // Reset the used hues (useful when generating new color schemes)
    public reset(): void {
        this.usedHues = [];
    }

    private hslToRgb(h: number, s: number, l: number): { r: number; g: number; b: number } {
        let r: number, g: number, b: number;

        if (s === 0) {
            r = g = b = l;
        } else {
            const hue2rgb = (p: number, q: number, t: number) => {
                if (t < 0) t += 1;
                if (t > 1) t -= 1;
                if (t < 1/6) return p + (q - p) * 6 * t;
                if (t < 1/2) return q;
                if (t < 2/3) return p + (q - p) * (2/3 - t) * 6;
                return p;
            };

            const q = l < 0.5 ? l * (1 + s) : l + s - l * s;
            const p = 2 * l - q;
            r = hue2rgb(p, q, h + 1/3);
            g = hue2rgb(p, q, h);
            b = hue2rgb(p, q, h - 1/3);
        }

        return {
            r: Math.round(r * 255),
            g: Math.round(g * 255),
            b: Math.round(b * 255)
        };
    }

    private rgbToHex(r: number, g: number, b: number): string {
        const toHex = (n: number): string => {
            const hex = n.toString(16);
            return hex.length === 1 ? '0' + hex : hex;
        };
        return `#${toHex(r)}${toHex(g)}${toHex(b)}`;
    }

    private getContrastColor(r: number, g: number, b: number): string {
        const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;
        return luminance > 0.5 ? '#000000' : '#FFFFFF';
    }
}

export interface ColorGeneratorResult {
    hex: string;
    rgb: { r: number; g: number; b: number };
    contrast: string; // Color for text that will be readable on this background
    hue: number;
}


export default HashColorGenerator;