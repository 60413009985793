import React from 'react';
import PlmJobList, { PlmJobListProps } from './PlmJobList';
import PlmControlContextProvider, { PlmControlContextProviderProps } from './plm-control-context/PlmControlContextProvider';
import './PlmControl.scss';
import withMethodContext from '../../../../module-components/method-context/withMethodContext';

export type PlmControlProps = PlmControlContextProviderProps & {
    constrainToList?: boolean;
} & ({
    type?: 'Job';
    listProps?: PlmJobListProps;
} |
{
    type?: 'Item';
    listProps?: any;
});

const PlmControl: React.FC<React.PropsWithChildren<PlmControlProps>> = ({ type = 'Job', constrainToList = false, listProps, children, ...baseProps }) => {
    return (<PlmControlContextProvider {...baseProps}>
        {type === 'Job' ? <PlmJobList constrainToList={constrainToList} {...listProps}/> : null}
        {children}
    </PlmControlContextProvider>)
}

export default withMethodContext(PlmControl);