import styled from "styled-components";
import { Skeleton } from 'primereact/skeleton';
import IconButton from "../../../../../common/components/icon/IconButton";
import React, { ReactNode, useCallback, useMemo } from "react";
import { Checkbox } from "@progress/kendo-react-inputs";
import InputGroup from "../../../components/input-group/InputGroup";
import TemplateInput, { makeTemplateDisplayString, parseTemplateValueString } from '../../../../../common/components/template-input/TemplateInput';
import Input from '../../../../../common/forms/controls/input/Input';
import Switch from '../../../../../common/forms/controls/switch/Switch';
import { Dropdown } from '../../../../../common/forms/controls/dropdown/Dropdown';
import DatePicker from '../../../../../common/forms/controls/date-picker/DatePicker';
import SwitchGroup from "../../../components/switch-group/SwitchGroup";
import { DateTime } from "luxon";
import { L } from "../../../../../abp/utils";
import Container from "../../../../../common/components/container/Container";
import _ from "lodash";
import { getIn } from "../../../../../common/forms/validation/na-va-form/commonUtils";
import { FIELD_DESIGNATION, FIELD_ID, FIELD_IS_TEMPLATE } from "../../../../../data/field-constants/GeneralConstants";
import classNames from "classnames";
import { getExtensionValue } from "../../../../../util/EntityUtils";
import usePlmControlContext from "../plm-control-context/usePlmControlContext";
import { PlmColumnDataDisplayProps, PlmControlLevel } from "./PlmControlDataDisplay";
import filter from "../../../../../util/FilterUtils";
import ShortcutKey from "../../../../../common/components/shortcut-key/ShortcutKey";
import useKeyDown from "../../../../../common/hooks/useKeyDown";
import useKeyCombination from "../../../../../common/hooks/useKeyCombination";
import { AttachmentApi, AttachmentDto } from "../../../../../client/http";
import useVisibility from "../../../../../common/hooks/useVisibility";
import Dialog from "../../../../../common/components/dialog/Dialog";
import useApi from "../../../../../common/hooks/useApi";
import { showNotification } from "../../../../../common/components/notifications/NotificationHost";

export type PlmControlSection = 'job' | 'jobTask' | 'jobPlanningLines' | 'jobTaskTransfer' | 'jobTaskTransferLines' | 'jobType' | 'jobTypePosAdj' | 'jobTypeNegAdj' | 'jobTypeLines' | 'item';

export type PlmControlBaseProps = {
    columnConfig: PlmItemColumnConfig;
    controlState: PlmControlState;
    readOnly?: boolean;
    selectable?: boolean;
    multiSelect?: boolean;
    fetchFromAllProviders?: boolean;
    dispatch:
    (args: {
        type: 'selected/changed' | 'expanded/changed' | 'isEditing/changed',
        payload: { section: PlmControlSection, key: string, item: any, parents?: { section: PlmControlSection, key: string }[], value: boolean; options?: any },
    }) => void,
}

export type PlmDataControlProps = {
    refetch: () => Promise<void>;
}

export const dispatchEndEdit = (section: PlmControlSection) =>
    ({
        type: "isEditing/changed",
        payload: { section: section, key: '', item: undefined, value: false }
    }) as Parameters<PlmControlBaseProps['dispatch']>[0];

export type PlmControlDispatchProps = {
    // onSelectionChanged: (section: PlmControlSection, keys: string[], items: any[], parents?: { section: PlmControlSection, key: string }[]) => void;
}

type PlmControlSectionState = {
    expanded?: { key: string, item: any, parents?: { section: PlmControlSection, key: string }[] }[],
    selected?: { key: string, item: any, parents?: { section: PlmControlSection, key: string }[] }[],
    filter: PlmItemFilter,
    showAttachments: boolean,
}

export type PlmControlState = { [key in PlmControlSection]: PlmControlSectionState } &
{
    isEditing?: { section: PlmControlSection, key: string, item: any, parents?: { section: PlmControlSection, key: string }[] }
}

export type PlmItemFilter = {
    internalFilters: any[];
    externalFilters: any[];
}

const emptyFilter = {
    internalFilters: [],
    externalFilters: []
} as PlmItemFilter;

export type PlmItemColumnConfig = { [key in PlmControlSection]: PlmControlColumn[] };

export type PlmControlColumn = {
    name: string;
    displayName: string;
    type: 'string' | 'number' | 'boolean' | 'date' | 'selection';
    selectionData?: { key: string, value: string }[],
    group?: string,
    readOnly?: boolean;
    isExtensionField?: boolean;
    size?: number;
    error?: boolean | ((item: any) => boolean);
}

export const defaultPlmControlState = {
    job: {
        filter: emptyFilter,
        showAttachments: true
    },
    jobTask: {
        filter: emptyFilter,
        showAttachments: true
    },
    jobPlanningLines: {
        filter: emptyFilter
    },
    jobTaskTransfer: {
        filter: emptyFilter
    },
    jobTaskTransferLines: {
        filter: emptyFilter
    },
    jobTypeLines: {
        filter: emptyFilter
    },
    jobType: {
        filter: emptyFilter
    },
    jobTypePosAdj: {
        filter: emptyFilter
    },
    jobTypeNegAdj: {
        filter: emptyFilter
    },
    item:{
        filter:emptyFilter
    },
    isEditing: undefined
} as PlmControlState;

export const PlmControlMethods = {
    getTemplateFieldNames: 'getTemplateFieldNames',
    transformTemplateFields: 'transformTemplateFields'
};

export const isTemplateFilter = (showTemplates?: boolean) => showTemplates === undefined ? [] : [filter(FIELD_IS_TEMPLATE).equals(showTemplates)];

export const RowSkeletons: React.FC<{ level: PlmControlLevel }> = ({ level }) => {
    return <div className={classNames('item-row', `level-${level}`, 'flex-column mt-3')}>
        <Skeleton height="2rem" className="mb-2"></Skeleton>
        <Skeleton height="2rem" className="mb-2"></Skeleton>
        <Skeleton height="2rem" className="mb-2"></Skeleton>
    </div>
}

export const ItemSkeleton: React.FC<{ level: PlmControlLevel }> = ({ level }) => {
    return <div className={classNames('item-row', `level-${level}`, 'flex-column mt-3')}>
        <Skeleton height="3rem" className="mb-2"></Skeleton>
    </div>
}

export const Header = styled.div.attrs({ className: 'col' }) <{ $columnCount: number, $level: number }>`
    width: ${p => `calc(calc(100% - ${p.$level * 3}rem) / ${p.$columnCount})`};
`;

export const Column = styled.div.attrs({ className: 'col' }) <{ $columnCount: number, $level: number, $error?: boolean }>`
    width: ${p => `calc(calc(100% - ${(p.$level - 1) * 3}rem) / ${p.$columnCount})`};
    background-color: ${p => p.$error ? '#ff000040 !important' : 'none'};
`;

export type SelectionColumnProps = {
    selectionChanged?: (selected: boolean, modifierKeys: string[]) => void;
    isSelected?: boolean;
    isHeader?: boolean;
}

export const SelectionColumn: React.FC<SelectionColumnProps> = ({ selectionChanged, isSelected, isHeader }) => {
    return <div className="col col__selection">
        {!isHeader && <Checkbox onChange={e => { selectionChanged && selectionChanged(e.value, [e.nativeEvent.ctrlKey ? 'ctrl' : '', e.nativeEvent.shiftKey ? 'shift' : '']) }} value={isSelected} />}
    </div>
}

export const RefetchPlmAttachments = 'RefetchPlmAttachments';

export type RefetchPlmAttachmentsEvent = {
    section: PlmControlSection,
    id: any
}

export type AttachmentColumnProps = {
    section: PlmControlSection;
    attachments?: AttachmentDto[];
    isHeader?: boolean;
}

export const AttachmentColumn: React.FC<AttachmentColumnProps> = ({ section, attachments, isHeader }) => {
    let content: ReactNode;
    const [, attachmentApi] = useApi<AttachmentDto, AttachmentApi>(c => new AttachmentApi(c));
    const [linkDialogVisible, showLinkDialog, hideLinkDialog] = useVisibility(false);

    const deleteAttachment = useCallback((a: AttachmentDto) => {
        try {
            attachmentApi?.apiServicesAppAttachmentDeleteDelete(a.id).then(() => {
                abp.event.trigger(RefetchPlmAttachments, { section, id: a.attachedToId } as RefetchPlmAttachmentsEvent);
                showNotification('Anhang gelöscht', 'Der Anhang wurde gelöscht.', 'warning', 5000, true, 'bottomLeft');
            });
        }
        catch {
            showNotification('Löschen fehlgeschlagen', 'Fehler beim Löschen des Anhangs', 'error', undefined, true, 'bottomLeft');
        }
    }, [attachmentApi, section]);

    if (isHeader) {
        content = L('Attachments');
    } else {
        switch ((attachments ?? []).length) {
            case 0:
                content = <span className="disabled">Keine Links</span>;
                break;
            case 1:
                const a = attachments![0];
                content =
                    <div className="d-flex align-items-baseline">
                        <span onClick={() => !a.isBinaryObject && a.targetUrl && window.open(a.targetUrl, '_blank', 'noopener')} className={classNames({ 'disabled': a.isBinaryObject || !a.targetUrl })} title="Link öffnen">{attachments!.length} Link</span>
                        <IconButton className="ms-1" name="icon-trash" onClick={() => deleteAttachment(attachments![0])} size="1rem" style={{ position: 'relative', top: '-0.2rem' }} title="Link löschen" />
                    </div>
                break;
            default:
                content =
                    <div className="d-flex align-items-baseline">
                        <span onClick={() => { showLinkDialog() }} title="Linkübersicht öffnen" >{attachments?.length} Links</span>
                        <IconButton className="ms-1" name="icon-view-file" onClick={() => attachments?.filter(a => !a.isBinaryObject && a.targetUrl).map(a => window.open(a.targetUrl, '_blank', 'noopener'))} title="Alle Links öffnen" size="1rem" style={{ position: 'relative', top: '-0.2rem' }} />
                        {
                            linkDialogVisible && <Dialog isOpen={linkDialogVisible} onClose={hideLinkDialog} title={L('Attachments')} dialogType="notice" actions={[]} content={
                                <div>
                                    {attachments?.map(a => (
                                        <div className="d-flex align-items-baseline">
                                            <div className={classNames("file-attachment-link", { 'disabled': a.isBinaryObject || !a.targetUrl })} onClick={() => !a.isBinaryObject && a.targetUrl && window.open(a.targetUrl, '_blank', 'noopener')} title="Link öffnen">
                                                {a.isBinaryObject ? (!a.description || a.description === '' ? 'Binärobjekt' : a.description) : a.targetUrl}
                                            </div>
                                            <IconButton className="ms-1" name="icon-trash" onClick={() => deleteAttachment(a)} size="1rem" style={{ position: 'relative', top: '-0.2rem' }} title="Link löschen" />
                                        </div>
                                    ))}
                                </div>
                            } />
                        }
                    </div>
                break;
        }
    }

    return <div className="col col__attachments">
        {content}
    </div>
}

export type ActionColumnActionType = 'edit' | 'save' | 'cancel' | 'delete' | 'duplicate' | 'addRow';

export type ActionColumnProps = {
    onAction?: (action: ActionColumnActionType) => void;
    readOnly?: boolean;
    isHeader?: boolean;
    isEditMode?: boolean;
}
export const ActionColumn: React.FC<ActionColumnProps> = ({ onAction, isHeader = false, isEditMode = false, readOnly = false }) => {
    const { controlState } = usePlmControlContext();

    const isAnyEditing = !!controlState.isEditing?.key;

    const renderHeaderContent = useCallback(() => {
        if (readOnly) return null;

        return (<>
            <div className="placeholders">
                &middot;
            </div>
            <div className="actions">
                <IconButton name="icon-document-splash" size="1.4rem" onClick={() => onAction && onAction('addRow')} color="var(--bs-success)" />
            </div></>)
    }, [readOnly, onAction]);

    const renderRowContent = useCallback(() => {
        return (<>
            <div className="placeholders">
                &middot;
            </div>
            <div className="actions">
                {isEditMode ?
                    <>
                        <IconButton name="icon-cancel" size="1.4rem" onClick={() => onAction && onAction('cancel')} color="var(--bs-danger)" />
                        <IconButton name="icon-checked" size="1.4rem" onClick={() => onAction && onAction('save')} color="var(--bs-success)" />
                    </>
                    :
                    <>
                        <IconButton name="icon-trash" size="1.2rem" disabled={readOnly || isAnyEditing} onClick={() => onAction && onAction('delete')} color="var(--bs-danger)" />
                        <IconButton name="icon-edit" size="1.2rem" disabled={readOnly || isAnyEditing} onClick={() => onAction && onAction('edit')} color="var(--bs-primary)" />
                        {/* <IconButton name="icon-copy" size="1rem" onClick={() => onAction && !readOnly && onAction('duplicate')} /> */}
                    </>
                }
            </div>
        </>)
    }, [isEditMode, readOnly, onAction, isAnyEditing]);

    return (<div className={classNames('col col__action', { 'header': isHeader })} >
        {
            isHeader ?
                renderHeaderContent()
                : renderRowContent()
        }
    </div >)
}

const mapSortingTemplateString = (s: string) => {
    return (s ? `${s}` : '').includes('$${') ? makeTemplateDisplayString(parseTemplateValueString(s)) : s;
}

export const selectPlmControlTemplateSortProperty = (item: any) => {
    return getExtensionValue(item, 'templateDescription') ? mapSortingTemplateString(getExtensionValue(item, 'templateDescription')) : '';
}

export const selectPlmControlSortProperty = (item: any) => {
    if (Object.hasOwn(item, 'itemNumber')) {
        return mapSortingTemplateString(getIn(item, 'itemNumber'))
    } else if (Object.hasOwn(item, 'lineNumber')) {
        return mapSortingTemplateString(getIn(item, 'lineNumber'));
    } else if (getExtensionValue(item, 'lineNumber')) {
        return mapSortingTemplateString(getExtensionValue(item, 'lineNumber'));
    } else if (Object.hasOwn(item, FIELD_DESIGNATION)) {
        return mapSortingTemplateString(getIn(item, FIELD_DESIGNATION));
    }
}

export const CellRender: React.FC<{ value: any, columnConfig: PlmControlColumn, isEditMode: boolean, onChange: (value: any) => void }> = ({ value, columnConfig, isEditMode, onChange }) => {
    const { replacements } = usePlmControlContext();

    switch (columnConfig.type) {
        case 'string':
            return isEditMode && !columnConfig.readOnly ?
                <TemplateInput value={value} fontSize=".8rem" onChange={(v) => onChange(v)} readOnly={false} replacements={replacements} /> :
                <TemplateInput value={value} fontSize=".8rem" readOnly={true} replacements={replacements} />;
        case 'boolean':
            return <Switch value={value} onChange={(e) => onChange(e.target.value)} disabled={!isEditMode || columnConfig.readOnly} className="k-switch-xs" />;
        case 'number':
            return <Input type='number' value={value} disabled={!isEditMode || columnConfig.readOnly} onChange={e => onChange(e.target.value)} />;
        case 'selection':
            return isEditMode && !columnConfig.readOnly ?
                <Dropdown data={columnConfig.selectionData} value={columnConfig.selectionData?.find(x => x.key === value)}
                    dataItemKey="key" textField="value" name={columnConfig.name} onChange={e => onChange(e.target.value["key"])}
                    popupSettings={{ width: 'min(350px,15vw)' }} /> :
                <div className="col__selection read-only">{columnConfig.selectionData?.find(x => x.key === value)?.value}</div>;
        case 'date':
            return isEditMode && !columnConfig.readOnly ?
                <DatePicker value={value ? DateTime.fromISO(value).toJSDate() : value}
                    onChange={e => onChange(e.value ? DateTime.fromJSDate(e.value).toISO() : e.value)} />
                : <div>{value ? DateTime.fromISO(value).toLocaleString(DateTime.DATE_SHORT) : value}</div>;
        default:
            return null;
    }
}

export const GroupRender: React.FC<{ value: any, columnConfig: PlmControlColumn, isEditMode: boolean, isCollapsed?: boolean, onChange: (value: any) => void }> =
    ({ value, columnConfig, isEditMode = false, isCollapsed = false, onChange }) => {
        const { replacements } = usePlmControlContext();

        switch (columnConfig?.type) {
            case 'string':
                return <InputGroup type="string" label={columnConfig?.displayName} value={value} fontSize=".8rem" onChange={(v) => onChange(v)} readOnly={!isEditMode} replacements={replacements}
                    valueRender={<TemplateInput value={value} fontSize=".8rem" readOnly={true} replacements={replacements} />} isCollapsed={isCollapsed} />
            case 'boolean':
                return <SwitchGroup label={columnConfig?.displayName} value={value} onChange={(e) => onChange(e.target.value)}
                    disabled={!isEditMode || columnConfig?.readOnly} isCollapsed={isCollapsed} className="k-switch-xs" />
            case 'number':
                return <InputGroup type="number" label={columnConfig?.displayName} value={value} disabled={!isEditMode || columnConfig?.readOnly}
                    onChange={e => onChange(e.target.value)} isCollapsed={isCollapsed} />
            case 'selection':
                return <InputGroup type="selection" data={columnConfig?.selectionData} value={columnConfig.selectionData?.find(x => x.key === value)} dataItemKey="key" textField="value"
                    name={columnConfig?.name} onChange={e => onChange(e.target.value["key"])} popupSettings={{ width: 'min(350px,15vw)' }}
                    disabled={!isEditMode || columnConfig?.readOnly} isCollapsed={isCollapsed} label={columnConfig?.displayName}
                    valueRender={columnConfig.selectionData?.find(x => x.key === value)?.value} />
            case 'date':
                return <InputGroup type="date" label={columnConfig?.displayName} value={value ? DateTime.fromISO(value).toJSDate() : value}
                    onChange={e => onChange(e.value ? DateTime.fromJSDate(e.value).toISO() : e.value)} isCollapsed={isCollapsed}
                    disabled={!isEditMode || columnConfig?.readOnly}
                    valueRender={value ? DateTime.fromISO(value).toLocaleString(DateTime.DATE_SHORT) : value} />
            default:
                return null;
        }
    }



export type PlmControlGroupRenderDisplayProps<T> = PlmColumnDataDisplayProps<T> & {
    wrapperClassName?: string;
    hideUngroupedColumns?: boolean;
}

export const PlmControlGroupRenderDisplay: React.FC<PlmControlGroupRenderDisplayProps<any>> = ({ item, section, keyField = FIELD_ID, level, className,
    hideActionColumn = false, expandable, onLocalDispatch, onChange, onAction, hideUngroupedColumns = false, wrapperClassName }) => {

    const { controlState, columnConfig, selectable, readOnly, dispatch } = usePlmControlContext();

    const isSelected = item && !!controlState[section].selected?.find(x => x.key === getIn(item, keyField));
    const isExpanded = item && !!controlState[section].expanded?.find(x => x.key === getIn(item, keyField));
    const isEditing = (item && controlState.isEditing?.section === section && controlState.isEditing.key === getIn(item, keyField)) ?? false;

    const localDispatch = useCallback((args: Parameters<PlmControlBaseProps['dispatch']>[0]) => {

        onLocalDispatch && onLocalDispatch(args);

        dispatch(args);
    }, [dispatch, onLocalDispatch]);

    const handleAction = useCallback((action: ActionColumnActionType) => {
        if (readOnly) return;

        switch (action) {
            case 'edit':
                localDispatch({
                    type: 'isEditing/changed',
                    payload: {
                        section, key: getIn(item, keyField), item, value: true
                    }
                });
                localDispatch({
                    type: 'expanded/changed',
                    payload: { section, key: getIn(item, keyField), item, value: true }
                })
                break;
            case 'cancel':
                localDispatch({
                    type: 'isEditing/changed',
                    payload: {
                        section, key: '', item, value: false
                    }
                });
                localDispatch({
                    type: 'expanded/changed',
                    payload: { section, key: getIn(item, keyField), item, value: false }
                })
                break;
            default:
                break;
        }

        onAction && onAction(action, section, getIn(item, keyField), item);
    }, [localDispatch, item, keyField, section, readOnly, onAction]);

    const columns = columnConfig[section];
    const unGroupedColumns = useMemo(() => columns.filter(c => !c?.group), [columns]);
    const groupedColumns = useMemo(() => _.groupBy(columns.filter(c => !!c?.group), (c) => c?.group), [columns]);
    const columnGroups = Object.keys(groupedColumns);

    const handleSave = useCallback(() => {
        if (isEditing) {
            onAction && onAction('save', section, getIn(item, keyField), item);
        };
    }, [isEditing, section, item, keyField, onAction]);

    const [isCtrlDown] = useKeyDown('Control');
    useKeyCombination('s', 'ctrl', handleSave);

    return (<div className={classNames(wrapperClassName, 'item-row')}>
        {expandable && <div className={classNames('btn__expand', `level-${level}`)}>
            <IconButton name={isExpanded ? 'icon-expand-arrow' : 'icon-forward'} size="2rem"
                onClick={() => dispatch({
                    type: 'expanded/changed',
                    payload: { section, key: getIn(item, keyField), item, value: !isExpanded }
                })} />
        </div>
        }
        <div className={classNames(className, 'item-row', { 'in-edit': isEditing, 'selected': isSelected, 'has-action': !hideActionColumn })}>
            {hideUngroupedColumns ?
                null :
                unGroupedColumns.map(c => {
                    return (<GroupRender columnConfig={c} value={c?.isExtensionField ? getExtensionValue(item, c?.name) : getIn(item, c?.name)}
                        onChange={(v) => onChange && onChange(c, v)} isEditMode={isEditing} isCollapsed={!isExpanded} />)
                })
            }
            {columnGroups.map((g) => {
                return (<Container label={L(g)} collapsedLabel={L(g + '_collapsed') === g + '_collapsed' ? L(g) : L(g + '_collapsed')} isCollapsed={!isExpanded}>
                    {groupedColumns[g]?.map(c => {
                        return (<GroupRender columnConfig={c} value={c?.isExtensionField ? getExtensionValue(item, c?.name) : getIn(item, c?.name)}
                            onChange={(v) => onChange && onChange(c, v)} isEditMode={isEditing} isCollapsed={!isExpanded} />)
                    })}
                </Container>);
            })}
            {!hideActionColumn && <ActionColumn isEditMode={isEditing} readOnly={readOnly} onAction={handleAction} />}
            <ShortcutKey shortcutKey={(isCtrlDown && isEditing) ? 'S' : undefined} style={{ left: '-2.2rem', opacity: '100%' }} />
        </div>
    </div>)
}