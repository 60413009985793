import { useCallback } from "react";
import usePlmControlContext from "./plm-control-context/usePlmControlContext"
import { ItemApi, ItemDto } from "../../../../client/http";
import { FIELD_ID, FIELD_IS_TEMPLATE, FIELD_ITEM, FIELD_ITEM_ID, FIELD_NUMBER } from "../../../../data/field-constants/GeneralConstants";
import { getIn } from "../../../../common/forms/validation/na-va-form/commonUtils";
import useInternalPlmControlData from "../../hooks/useInternalPlmControlData";
import { Settings_ProviderName, Settings_ServiceConnectionId } from "../../../../util/SettingNames";
import { NEW_ENTITY_ID } from "../../../../util/EntityUtils";
import { ItemDefaultValues } from "../../data/entityDefaults";
import { showNotification } from "../../../../common/components/notifications/NotificationHost";
import { ActionColumnActionType, ItemSkeleton } from "./components/PlmControlComponents";
import classNames from "classnames";
import { L } from "../../../../abp/utils";
import NaVaForm from "../../../../common/forms/validation/na-va-form/NaVaForm";
import ModuleComponentProvider from "../../../../module-components/module-context/ModuleComponentProvider";
import { AppParts } from "../../../AppParts";
import filter from "../../../../util/FilterUtils";
import Button from "../../../../common/forms/controls/button/Button";
import Icon from "../../../../common/components/icon/Icon";
import { PlmColumnDataDisplay } from "./components/PlmControlDataDisplay";
import PlmColumnHeaderDisplay from "./components/PlmControlHeaderDisplay";
import useCrudApi from "../../../../common/hooks/useCrudApi";
import { ItemSelectionProps } from "../../../../module-components/service-entities/ServiceEntityControlProps";
import { ItemItemRender, TemplatedItemItemRender } from "../../service-entitites/ItemSelection";

export type PlmItemControlProps = {
    isLoading: boolean;
    item: any;
    onItemChanged: (action: 'created' | 'updated' | 'deleted', id?: string, code?: string) => void;
}

const PlmItemControl: React.FC<PlmItemControlProps> = ({ isLoading, item, onItemChanged }) => {

    const { readOnly, showTemplates } = usePlmControlContext();

    const onSave = useCallback((item: ItemDto, successful: boolean) => {
        if (successful) {
            onItemChanged('updated', getIn(item, FIELD_ID), getIn(item, FIELD_NUMBER));
        }
    }, [onItemChanged]);

    const [internalItem, setInternalItem, handleChange, handleAction] = useInternalPlmControlData<ItemDto, ItemApi>(
        item,
        {},
        'item',
        c => new ItemApi(c),
        c => c.apiServicesAppItemCreatePost,
        c => c.apiServicesAppItemUpdatePut,
        c => c.apiServicesAppItemDeleteDelete,
        undefined,
        undefined,
        onSave
    );

    const [, api, create] = useCrudApi<ItemDto, ItemApi>(c => new ItemApi(c),
        undefined,
        (c, d) => c.apiServicesAppItemCreatePost(({
            ...d,
            extensionData: JSON.stringify(d.extensions),
            serviceProvider: d.serviceProvider ?? abp.setting.get(Settings_ProviderName),
            serviceConnectionId: d.serviceConnectionId ?? abp.setting.getInt(Settings_ServiceConnectionId)
        }))
    )

    const handleCreate = useCallback(async () => {
        if (!api || !create) return;

        const itemId = getIn(internalItem, FIELD_ID);

        if (itemId !== undefined || itemId != null) return;

        try {
            setInternalItem({ id: NEW_ENTITY_ID } as ItemDto);
            var result = await create(api, { ...internalItem, ...ItemDefaultValues, [FIELD_ID]: undefined, [FIELD_IS_TEMPLATE]: showTemplates })

            onItemChanged('created', (result as any).result[FIELD_ID], undefined);
        } catch (error) {
            showNotification('Erstellen fehlgeschlagen', 'Fehler beim Speichern aufgetreten', 'error', undefined, true);
            return false;
        }
    }, [internalItem, api, showTemplates, setInternalItem, onItemChanged, create])

    const handleSelect = useCallback(async (i: ItemDto) => {
        const itemId = getIn(internalItem, FIELD_ID);

        if (itemId !== undefined || itemId != null) return;

        try {
            onItemChanged('updated', i.id, i.number);

        } catch (error) {
            showNotification('Artikel festlegen fehlgeschlagen', 'Fehler beim Speichern aufgetreten', 'error', undefined, true);
            return false;
        }
    }, [internalItem, onItemChanged]);

    const handleActionInternal = useCallback((action: ActionColumnActionType) => {
        handleAction(action);

        switch (action) {
            case 'save':
                onItemChanged('updated', getIn(internalItem, FIELD_ID), getIn(internalItem, FIELD_NUMBER));
                break;
            case 'delete':
                onItemChanged('deleted', getIn(internalItem, FIELD_ID))
                break;

        }
    }, [internalItem, handleAction, onItemChanged]);

    if (!item && isLoading) {
        return (<>
            <ItemSkeleton level={1} />
        </>
        )
    }

    return <>
        {internalItem ?
            <div className={classNames('plm-item-wrapper', { 'unsaved': getIn(internalItem, FIELD_ID) === NEW_ENTITY_ID })}>
                <PlmColumnHeaderDisplay section="item" level={1} headersLevel={3} onAction={()=>{}} readOnly={readOnly || getIn(internalItem, FIELD_ID) === NEW_ENTITY_ID}
                    hasNoData={!isLoading && !internalItem} noDataLabel={L('Kein Artikel vorhanden')} />
                <PlmColumnDataDisplay item={internalItem} section="item" level={2} className="plm-job-type-line-item"
                    onChange={handleChange} onAction={handleActionInternal} />
            </div> :
            <div className="plm-item-item item__empty my-2 d-flex align-items-center">
                <span className="me-3">
                    {L('Kein Artikel hinterlegt')}
                </span>
                {!readOnly &&
                    <div className="d-flex gap-3">
                        <NaVaForm
                            initialValues={{}}
                            onSubmit={() => { }}
                        >
                            <ModuleComponentProvider appPartName={AppParts.ServiceEntities.Selection.Item} getSingleComponent={true}
                                props={{
                                    name: FIELD_ITEM,
                                    foreignKeyName: FIELD_ITEM_ID,
                                    itemFilters: showTemplates ? [filter(FIELD_IS_TEMPLATE).equals(true)] : undefined,
                                    onChange: v => handleSelect(v.target.value),
                                    textField: FIELD_ID,
                                    itemRender: showTemplates ? TemplatedItemItemRender : ItemItemRender
                                } as ItemSelectionProps} />
                        </NaVaForm>
                        <Button color="light" label="" onClick={handleCreate}>
                            <Icon name="icon-add" size="1.5rem" />
                            {L('Create')}
                        </Button>
                    </div>
                }
            </div>
        }
    </>
}

export default PlmItemControl;