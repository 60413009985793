const dot = (value: string) => value + ':';

const Application = (base: string) => ({
    MainLogo: base + 'MainLogo',
    CollapsedLogo: base + 'CollapsedLogo',
    LoginLogo: base + 'LoginLogo',
    ProviderLogo: (providerName: string) => base + `ProviderLogo_${providerName}`
});

const BudgetPlanning = (base: string) => ({
    BudgetWidget: dot(base) + 'BudgetWidget',
    JobTaskDetails: dot(base) + 'JobTaskDetails'
})

const Dashboard = (base: string) => ({
    Cards: base + 'Cards',
});

const ServiceEntity = (base: string) => ({
    Category: base + 'Category',
    Customer: base + 'Customer',
    Item: base + 'Item',
    Job: base + 'Job',
    JobTask: base + 'JobTask',
    JobType: base + 'JobType',
    Resource: base + 'Resource',
    TimeEntity: base + 'TimeEntity',
});

const ServiceEntities = (base: string) => ({
    Details: ServiceEntity(base + dot('Details')),
    Grid: ServiceEntity(base + dot('Grid')),
    Selection: ServiceEntity(base + dot('Selection'))
});

const TimeTracking = (base: string) => ({
    TimeEntities: {
        EditorFields: base + 'TimeEntities:EditorFields', EntitiesFilter: base + "TimeEntities:EntitiesFilter", EntityDetails: base + "TimeEntities:EntityDetails", EntityData: base + "TimeEntities:EntityData",
        ContextMenuItemsHost: base + 'TimeEntities:ContextMenuItemsHost'
    }
});

const Reporting = (base: string) => ({
    ReportEditor: { Tools: base + 'ReportEditor:Tools' },
    ReportList: { EntitiesFilter: base + 'ReportList:EntitiesFilter' },
    ReportWizard: { 
        Step0Addition: base + 'ReportWizard: Step0Addition' ,
        Step1Addition: base + 'ReportWizard: Step1Addition' ,
        Step2Addition: base + 'ReportWizard: Step2Addition' ,
        Step3Addition: base + 'ReportWizard: Step3Addition'
    }
});

const Settings = (base: string) => ({
    SettingList: base + 'SettingList'
})

export const AppParts = {
    Application: Application('Application'),
    BudgetPlanning: BudgetPlanning('BudgetPlanning'),
    Dashboard: Dashboard('Dashboard'),
    ModuleRegistration: 'ModuleRegistration',
    ServiceEntities: ServiceEntities(dot('ServiceEntities')),
    TimeTracking: TimeTracking(dot('TimeTracking')),
    Reporting: Reporting(dot('Reporting')),
    Settings: Settings(dot('Settings')),
};